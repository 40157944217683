<template>
    <div class="relative">
        <Chart :options="chart_options"></Chart>

        <div
            v-if="!history_list.length"
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
            <div class="util-flex-center flex-col">
                <img
                    class="w-[140px] h-[140px]"
                    :src="require('@/assets/images/no_data.png')"
                />
                <p class="mt-2 text-desc-text text-sm">
                    {{ $t('common.no_data') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

const renderYAxis = () => {
    return {
        title: {
            text: ''
        },
        labels: {
            enabled: false
        },
        visible: true
    }
}

export default {
    components: {
        Chart
    },

    props: ['history_list'],

    data() {
        return {
            chart_options: {
                chart: {
                    type: 'spline',
                    height: 280
                },
                title: {
                    text: ''
                },
                legend: {
                    enabled: false,
                    floating: true
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    minTickInterval: 6,
                    tickInterval: 1,
                    endOnTick: true,
                    startOnTick: true
                },
                tooltip: {
                    pointFormatter: null,
                    shared: true
                },
                yAxis: [],
                series: []
            }
        }
    },

    computed: {
        s__currency() {
            return this.$store.getters.app.s__currency
        }
    },

    watch: {
        history_list() {
            this.changeSeriesChart()
        }
    },

    mounted() {
        // const m__formatterColumn = (x, y) =>
        //     this.m__formatterColumn(x, y, this.s__currency)
        // const p__getNameByTrend = this.p__getNameByTrend
        // this.chart_options.tooltip.pointFormatter = function () {
        //     const point = this
        //     const series = point.series
        //     const y = m__formatterColumn(point.y || 0, series.name)
        //     return `${p__getNameByTrend(series.name)}: <b>${y}</b><br/>`
        // }

        this.changeSeriesChart()
    },

    methods: {
        changeSeriesChart() {
            this.chart_options.xAxis.categories = this.history_list
                .map(
                    (item, idx) =>
                        this.history_list[this.history_list.length - 1 - idx]
                )
                .map((it) => it.date)

            const series = []
            const yAxis = []

            const data = this.history_list
                .map(
                    (item, idx) =>
                        this.history_list[this.history_list.length - 1 - idx]
                )
                .map((it) => +it.amount)

            series.push({
                name: 'Tiền',
                color: 'green',
                data,
                yAxis: 0,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            })

            yAxis.push(renderYAxis())

            this.chart_options.series = series

            this.chart_options.yAxis = yAxis
        }
    }
}
</script>
