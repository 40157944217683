<template>
    <div>
        <p class="mb-4">Lịch sử nạp tiền TKQC tiktok</p>

        <el-table max-height="600" :data="data_list">
            <el-table-column label="#" type="index" width="60" />

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                label="ID TKQC"
            >
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.advertiser_id }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column :show-overflow-tooltip="true" label="Tên TKQC">
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.advertiser_name }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                label="Code"
            >
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.transfer_code }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                label="Số tiền"
            >
                <template slot-scope="scope">
                    <p>
                        {{
                            p__renderMoneyByCurrency(
                                scope.row.cash_amount,
                                'VND'
                            )
                        }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                label="Thời gian nạp"
            >
                <template slot-scope="scope">
                    <p>
                        {{
                            scope.row.payment_time_unix
                                | f__format_moment($t('common.format_fulldate'))
                        }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                label="Người nạp"
            >
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.user_key.name }}
                    </p>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            class="mt-2"
            background
            layout="prev, pager, next"
            :current-page.sync="m__page_info.page"
            :page-size="m__page_info.page_size"
            :total="m__page_info.total_number"
        >
        </el-pagination>
    </div>
</template>

<script>
import { getManualPayments } from '@/services/atosa-tiktok-ads/payment'

export default {
    data() {
        return {
            key: '',
            data_list: [],
            m__page_info: {
                page: 1,
                page_size: 10,
                total_number: 0,
                total_page: 1
            }
        }
    },

    watch: {
        'm__page_info.page'() {
            this.getManualPayments()
        }
    },

    mounted() {
        if (this.$route.query.key) {
            this.key = this.$route.query.key
        }

        this.getManualPayments()
    },

    methods: {
        async getManualPayments() {
            const response = await getManualPayments(
                this.key,
                '1234',
                this.m__page_info.page
            )

            this.data_list = response.data.data

            this.m__page_info.page = response.data.page_result.page

            this.m__page_info.total_number = response.data.page_result.total
        }
    }
}
</script>
