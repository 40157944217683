<template>
    <div>
        <p>Nhập thông tin giao dịch Tiktok</p>

        <div class="flex flex-col space-y-4 mt-6">
            <el-input
                v-model="form_recharge.user_key"
                type="text"
                size="small"
                placeholder="Nhập user key"
            >
            </el-input>

            <el-input
                v-model="form_recharge.transfer_code"
                type="text"
                size="small"
                placeholder="Mã giao dịch QR (nếu có)"
            >
            </el-input>

            <el-input
                v-model="form_recharge.advertiser_id"
                type="text"
                size="small"
                placeholder="Mã tài khoản quảng cáo ID"
            >
            </el-input>

            <el-input
                v-model="form_recharge.advertiser_name"
                type="text"
                size="small"
                placeholder="Tên tài khoản quảng cáo"
            >
            </el-input>

            <label-outside-input-select label="Số tiền">
                <el-input
                    v-model="form_recharge.cash_amount"
                    type="number"
                    size="small"
                    placeholder="Số tiền"
                >
                </el-input>
            </label-outside-input-select>

            <label-outside-input-select label="Phí giao dịch">
                <el-input
                    v-model="form_recharge.fee_amount"
                    type="number"
                    size="small"
                    placeholder="Phí giao dịch"
                >
                </el-input>
            </label-outside-input-select>

            <el-input
                v-model="form_recharge.advertiser_currency"
                type="text"
                size="small"
                placeholder="Tiền tệ"
                disabled
                maxlength="100"
            >
            </el-input>
        </div>

        <el-button @click="addPaymentManual" class="mt-4">
            Lưu giao dịch
        </el-button>
    </div>
</template>

<script>
import { addPaymentManualTiktok } from '@/services/atosa-tiktok-ads/payment'

export default {
    data() {
        return {
            key: '',
            form_recharge: {
                user_key: '',
                advertiser_id: '',
                advertiser_name: '',
                advertiser_currency: 'VND',
                cash_amount: 0,
                fee_amount: 0,
                transfer_code: '',
                bank_time_unix: 1706783410,
                payment_time_unix: 1706783410
            }
        }
    },

    mounted() {
        if (this.$route.query.key) {
            this.key = this.$route.query.key
        }
    },

    methods: {
        async addPaymentManual() {
            const response = await addPaymentManualTiktok({
                key: this.key,
                ...this.form_recharge
            })

            if (response.code === 0) {
                this.p__showNotify('success', 'Lưu data thành công')
            }
        }
    }
}
</script>
