<template>
    <div class="component-box">
        <div class="flex gap-4">
            <div class="flex-1">
                <TiktokTransaction></TiktokTransaction>
            </div>
            <div class="flex-1">
                <p>Nạp tiền TKQC Tiktok</p>

                <div class="flex flex-col space-y-4 mt-6">
                    <el-input
                        v-model="form_recharge.user_key"
                        type="text"
                        size="small"
                        placeholder="Nhập user key"
                    >
                    </el-input>

                    <el-input
                        v-model="form_recharge.transfer_code"
                        type="text"
                        size="small"
                        placeholder="Mã giao dịch QR (nếu có)"
                    >
                    </el-input>

                    <el-input
                        v-model="form_recharge.tiktok_account_id"
                        type="text"
                        size="small"
                        placeholder="Mã tiktok business ID"
                    >
                    </el-input>

                    <el-input
                        v-model="form_recharge.advertiser_id"
                        type="text"
                        size="small"
                        placeholder="Mã tài khoản quảng cáo ID"
                    >
                    </el-input>

                    <el-input
                        v-model="form_recharge.advertiser_name"
                        type="text"
                        size="small"
                        placeholder="Tên tài khoản quảng cáo"
                    >
                    </el-input>

                    <el-input
                        v-model="form_recharge.system_bc"
                        type="text"
                        size="small"
                        placeholder="Mã BC ID của tkqc (dùng khi không phải BC Atosa)"
                    >
                    </el-input>

                    <label-outside-input-select label="Số tiền">
                        <el-input
                            v-model="form_recharge.cash_amount"
                            type="number"
                            size="small"
                            placeholder="Số tiền"
                        >
                        </el-input>
                    </label-outside-input-select>

                    <el-input
                        v-model="form_recharge.advertiser_currency"
                        type="text"
                        size="small"
                        placeholder="Tiền tệ"
                        disabled
                        maxlength="100"
                    >
                    </el-input>
                </div>

                <el-button @click="addPaymentManual" class="mt-4">
                    Nạp tiền
                </el-button>
            </div>
        </div>

        <ManualPayment class="mt-8"></ManualPayment>
    </div>
</template>

<script>
import { addPaymentManual } from '@/services/atosa-tiktok-ads/payment'
import TiktokTransaction from './tiktok-transaction'
import ManualPayment from './manual-payment'

export default {
    components: {
        ManualPayment,
        TiktokTransaction
    },

    data() {
        return {
            key: '',
            form_recharge: {
                user_key: '',
                transfer_code: '',
                tiktok_account_id: '',
                advertiser_id: '',
                advertiser_name: '',
                advertiser_currency: 'VND',
                system_bc: '',
                cash_amount: 0
            }
        }
    },

    mounted() {
        if (this.$route.query.key) {
            this.key = this.$route.query.key
        }
    },

    methods: {
        async addPaymentManual() {
            const response = await addPaymentManual({
                key: this.key,
                user_key: this.form_recharge.user_key,
                transfer_code: this.form_recharge.transfer_code,
                tiktok_account_id: this.form_recharge.tiktok_account_id,
                advertiser_id: this.form_recharge.advertiser_id,
                advertiser_name: this.form_recharge.advertiser_name,
                advertiser_currency: this.form_recharge.advertiser_currency,
                system_bc: this.form_recharge.system_bc,
                cash_amount: this.form_recharge.cash_amount
            })

            if (response.code === 0) {
                this.p__showNotify('success', 'Nạp thành công')
            }
        }
    }
}
</script>
