<template>
    <div>
        <div class="component-box">
            <div class="mb-2 flex items-center space-x-2">
                <label-outside-input-select label="Tìm kiếm theo TK ATOSA">
                    <el-input
                        v-model="mail"
                        :clearable="true"
                        size="small"
                        class="w-[240px]"
                        @clear="getHistoryPayment(true)"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="getHistoryPayment(true)"
                        ></el-button>
                    </el-input>
                </label-outside-input-select>
                <label-outside-input-select label="Tìm kiếm theo TKQC">
                    <el-input
                        v-model="name"
                        :clearable="true"
                        size="small"
                        class="w-[240px]"
                        @clear="getHistoryPayment(true)"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="getHistoryPayment(true)"
                        ></el-button>
                    </el-input>
                </label-outside-input-select>
                <label-outside-input-select label="Tìm kiếm theo code">
                    <el-input
                        v-model="code_code"
                        :clearable="true"
                        size="small"
                        class="w-[240px]"
                        @clear="getHistoryPayment(true)"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="getHistoryPayment(true)"
                        ></el-button>
                    </el-input>
                </label-outside-input-select>
                <el-date-picker
                    v-model="ranger_date"
                    class="w-[360px]"
                    size="small"
                    type="daterange"
                    :clearable="false"
                    :start-placeholder="$t('date_picker.placeholder.start')"
                    :end-placeholder="$t('date_picker.placeholder.end')"
                    :format="$t('common.format_value_date_picker')"
                    value-format="yyyy-MM-dd"
                    :picker-options="m__picker_options"
                >
                </el-date-picker>

                <el-checkbox v-model="vat_checked">VAT</el-checkbox>

                <div
                    class="cursor-pointer p-2 rounded-lg bg-bg"
                    @click="handleExportExcel"
                >
                    <el-tooltip
                        class="item"
                        :content="$t(`common.export_excel`)"
                        effect="dark"
                        placement="top"
                    >
                        <ExcelSvg></ExcelSvg>
                    </el-tooltip>
                </div>
            </div>

            <el-table
                v-loading="m__loading"
                max-height="600"
                :data="history_list"
                show-summary
                :summary-method="getSummaries"
            >
                <el-table-column label="#" type="index" width="60" />

                <el-table-column
                    width="200"
                    :show-overflow-tooltip="true"
                    label="Tên TK ATOSA"
                >
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.body.customer.email }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column width="160" label="Tài khoản quảng cáo">
                    <template slot-scope="scope">
                        <div
                            v-if="
                                scope.row.body.event.transfer &&
                                scope.row.body.event.transfer.extra_data &&
                                scope.row.body.event.transfer.extra_data.extra
                            "
                        >
                            <p>
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.advertiser_name
                                }}
                            </p>
                            <p>
                                ID:
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.advertiser_id
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column width="160" label="ID BC nạp vào">
                    <template slot-scope="scope">
                        <div
                            v-if="
                                scope.row.body.event.transfer &&
                                scope.row.body.event.transfer.extra_data &&
                                scope.row.body.event.transfer.extra_data.extra
                            "
                        >
                            <p>
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.system_bc
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column width="200" label="Số tiền từng phần">
                    <template slot-scope="scope">
                        <p>
                            Số tiền nạp:
                            {{
                                p__renderMoneyByCurrency(
                                    scope.row.body.event.transfer.extra_data
                                        .extra.cash_amount,
                                    'VND'
                                )
                            }}
                        </p>
                        <p>
                            Số tiền phí:
                            {{
                                p__renderMoneyByCurrency(
                                    scope.row.body.event.transfer.extra_data
                                        .extra.fee_amount,
                                    'VND'
                                )
                            }}
                        </p>
                        <b>
                            {{
                                (+scope.row.body.event.transfer.extra_data.extra
                                    .fee_amount /
                                    +scope.row.body.event.transfer.extra_data
                                        .extra.cash_amount) *
                                100
                            }}
                            %
                        </b>
                    </template>
                </el-table-column>

                <el-table-column
                    width="160"
                    prop="body.event.amount"
                    label="Tổng Số tiền"
                >
                    <template slot-scope="scope">
                        {{
                            p__renderMoneyByCurrency(
                                scope.row.body.event.amount,
                                'VND'
                            )
                        }}
                    </template>
                </el-table-column>

                <el-table-column
                    width="120"
                    :show-overflow-tooltip="true"
                    label="Code"
                >
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.body.event.transfer.trans_id }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column width="200" label="Nội dung chuyển khoản">
                    <template slot-scope="scope">
                        <p
                            v-if="
                                scope.row.body.event.transfer.trans_info
                                    .trans &&
                                scope.row.body.event.transfer.trans_info.trans
                                    .description
                            "
                        >
                            {{
                                scope.row.body.event.transfer.trans_info.trans
                                    .description
                            }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column width="240" label="Hóa đơn">
                    <template slot-scope="scope">
                        <div
                            v-if="
                                scope.row.body.event.transfer &&
                                scope.row.body.event.transfer.extra_data &&
                                scope.row.body.event.transfer.extra_data
                                    .extra &&
                                scope.row.body.event.transfer.extra_data.extra
                                    .i_s
                            "
                        >
                            <p>
                                MST :
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.i_c
                                }}
                            </p>
                            <p>
                                Tên công ty :
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.i_cn
                                }}
                            </p>
                            <p>
                                Địa chỉ :
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.i_a
                                }}
                            </p>
                            <p>
                                email :
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.i_e
                                }}
                            </p>
                            <p>
                                SĐT :
                                {{
                                    scope.row.body.event.transfer.extra_data
                                        .extra.i_p
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column width="160" label="Trạng thái">
                    <template slot-scope="scope">
                        <div v-if="scope.row.error_message">
                            <el-tag type="danger">Thất bại</el-tag>

                            <p>Lí do : {{ scope.row.error_message }}</p>
                        </div>

                        <el-tag v-else type="success">Thành công</el-tag>
                    </template>
                </el-table-column>

                <el-table-column width="160" label="Thời gian khách CK">
                    <template slot-scope="scope">
                        {{
                            (scope.row.body.event.transfer.created_at * 1000)
                                | f__format_moment($t('common.format_fulldate'))
                        }}
                    </template>
                </el-table-column>

                <el-table-column width="160" label="Thời gian nạp tiền vào BC">
                    <template slot-scope="scope">
                        {{
                            scope.row.created_at
                                | f__format_moment($t('common.format_fulldate'))
                        }}
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                class="mt-2"
                background
                layout="prev, pager, next"
                :current-page.sync="m__page_info.page"
                :page-size="m__page_info.page_size"
                :total="m__page_info.total_number"
            >
            </el-pagination>
        </div>

        <Recharge class="mt-2"></Recharge>

        <Detail class="mt-2"></Detail>
    </div>
</template>

<script>
import { getHistoryPayment } from '@/services/atosa-tiktok-ads/payment'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import rangerDateMixin from '@/plugins/mixins/ranger-date'
import moment from 'moment'
import Detail from './detail'
import Recharge from './recharge'
import ExcelSvg from '@/assets/svgs/excel.svg'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'

export default {
    components: {
        Detail,
        Recharge,
        ExcelSvg
    },

    mixins: [selectedTiktokBusinessMixin, rangerDateMixin],

    data() {
        return {
            vat_checked: false,
            key: '',
            name: '',
            mail: '',
            code_code: '',
            ranger_date: [
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 31),
                new Date()
            ],
            m__page_info: {
                page: 1,
                page_size: 50,
                total_number: 0,
                total_page: 1
            },
            history_list: []
        }
    },

    watch: {
        ranger_date() {
            this.getHistoryPayment()
        },
        vat_checked() {
            this.getHistoryPayment()
        },
        'm__page_info.page'() {
            this.getHistoryPayment()
        }
    },

    mounted() {
        if (this.$route.query.key) {
            this.key = this.$route.query.key
        }

        this.getHistoryPayment()
    },

    methods: {
        async fetchHistoryPayment(page) {
            try {
                const start_time = moment(this.ranger_date[0]).format('X')
                const end_time =
                    +moment(this.ranger_date[1]).format('X') + 86_400

                const response = await getHistoryPayment({
                    name: this.name,
                    mail: this.mail,
                    code: this.code_code,

                    page,
                    page_size: 100,
                    start_time,
                    end_time,
                    key: this.key
                })

                return {
                    page_count: response.data.page_result.page_count,
                    has_more: response.data.page_result.page_count > page,
                    list: response.data.data
                }
            } catch (error) {
                console.error(error)
            }

            return {
                page_count: 1,
                has_more: false,
                list: []
            }
        },

        async handleExportExcel() {
            let page = 1
            const result = []

            try {
                const response = await this.fetchHistoryPayment(page)
                result.push(...response.list)

                if (response.page_count > 1) {
                    while (page < response.page_count) {
                        page++

                        const temp = await this.fetchHistoryPayment(page)
                        result.push(...temp.list)
                    }
                }
            } catch (error) {
                console.error(error)
            }
            const new_result = result.filter(
                (v, i, a) =>
                    a.findIndex(
                        (v2) =>
                            v2.body.event.transfer.trans_info.key ===
                            v.body.event.transfer.trans_info.key
                    ) === i
            )

            const start_time = moment(this.ranger_date[0]).format('DD-MM-YYYY')
            const end_time = moment(this.ranger_date[1]).format('DD-MM-YYYY')

            const headers = [
                'Tên TK ATOSA',
                'ID TKQC',
                'Tài khoản quảng cáo',
                'ID BC nạp vào',
                'Số tiền nạp',
                'Số tiền phí',
                'Phần trăm phí',
                'Tổng số tiền',
                'Code',
                'Nội dung chuyển khoản',
                'Thời gian khách CK',
                'Thời gian nạp tiền vào BC'
            ]

            const body = new_result.map((item) => {
                return [
                    item.body.customer.email,
                    item.body.event.transfer.extra_data?.extra?.advertiser_id ||
                        '',
                    item.body.event.transfer.extra_data?.extra
                        ?.advertiser_name || '',
                    item.body.event.transfer.extra_data?.extra?.system_bc || '',
                    this.p__renderMoneyByCurrency(
                        item.body.event.transfer.extra_data?.extra
                            ?.cash_amount || 0,
                        'VND'
                    ),
                    this.p__renderMoneyByCurrency(
                        item.body.event.transfer.extra_data?.extra
                            ?.fee_amount || 0,
                        'VND'
                    ),
                    `${
                        ((+item.body.event.transfer.extra_data?.extra
                            ?.fee_amount || 0) /
                            (+item.body.event.transfer.extra_data?.extra
                                ?.cash_amount || 1)) *
                        100
                    } %`,
                    this.p__renderMoneyByCurrency(
                        item.body.event.amount,
                        'VND'
                    ),
                    item.body.event.transfer.trans_id,
                    item.body.event.transfer.trans_info?.trans?.description ||
                        '',
                    moment(item.body.event.transfer.created_at * 1000).format(
                        'HH:mm DD-MM-YYYY'
                    ),
                    moment(item.created_at).format('HH:mm DD-MM-YYYY')
                ]
            })

            const name = `doi_soat_${this.mail}_${this.name}_${this.code_code}_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        },

        async getHistoryPayment(refresh = false) {
            this.m__loading = true

            if (refresh) {
                this.m__page_info.page = 1
            }

            try {
                const start_time = moment(this.ranger_date[0]).format('X')
                const end_time =
                    +moment(this.ranger_date[1]).format('X') + 86_400

                const response = await getHistoryPayment({
                    name: this.name,
                    code: this.code_code,
                    mail: this.mail,
                    page: this.m__page_info.page,
                    page_size: this.m__page_info.page_size,
                    start_time,
                    end_time,
                    invoice: this.vat_checked ? '1' : '0',
                    key: this.key
                })

                if (response.code === 0) {
                    this.history_list = response.data.data
                    this.m__page_info.total_number =
                        response.data.page_result.total
                    this.m__page_info.total_page =
                        response.data.page_result.page_count
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const { property } = column

                const values = data.map((item) => {
                    if (property) {
                        const pathArray = property.split('.')
                        if (pathArray.length > 1) {
                            let value = item
                            for (const key of pathArray) {
                                value = value[key]
                            }

                            return Number(value)
                        }
                    }

                    return Number(item[property])
                })

                if (!values.every((value) => isNaN(value))) {
                    const total = this.calculateValue(values, property, data)

                    sums[index] = total
                } else {
                    sums[index] = ''
                }
            })
            return sums
        },

        calculateValue(values, column_property, data) {
            let total = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                } else {
                    return prev
                }
            }, 0)

            if (
                ['body.event.amount'].some((item) => column_property === item)
            ) {
                total = data.reduce((prev, curr) => {
                    const value = Number(curr.body.event.amount)
                    if (!isNaN(value)) {
                        return prev + value
                    } else {
                        return prev
                    }
                }, 0)
                total = `${this.p__renderMoneyByCurrency(total, 'VND')}`
            }
            return total
        }
    }
}
</script>
