<template>
    <div class="component-box">
        <div class="mb-2 flex items-center space-x-2">
            <label-outside-input-select label="Tìm kiếm theo TK ATOSA">
                <el-input
                    v-model="mail"
                    :clearable="true"
                    size="small"
                    class="w-[240px]"
                    @clear="getHistoryPayment()"
                >
                    <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="getHistoryPayment()"
                    ></el-button>
                </el-input>
            </label-outside-input-select>
            <label-outside-input-select label="Tìm kiếm theo TKQC">
                <el-input
                    v-model="name"
                    :clearable="true"
                    size="small"
                    class="w-[240px]"
                    @clear="getHistoryPayment()"
                >
                    <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="getHistoryPayment()"
                    ></el-button>
                </el-input>
            </label-outside-input-select>

            <el-date-picker
                v-model="ranger_date"
                class="w-[360px]"
                size="small"
                type="daterange"
                unlink-panels
                :clearable="false"
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :format="$t('common.format_value_date_picker')"
                value-format="yyyy-MM-dd"
                :picker-options="m__picker_options"
            >
            </el-date-picker>

            <div
                class="cursor-pointer p-2 rounded-lg bg-bg"
                @click="handleExportExcel"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <el-table
            v-loading="m__loading"
            max-height="420"
            :data="history_list"
            show-summary
            :summary-method="getSummaries"
        >
            <el-table-column label="#" type="index" width="60" />

            <el-table-column prop="amount" label="Số tiền">
                <template slot-scope="scope">
                    {{ p__renderMoneyByCurrency(scope.row.amount, 'VND') }}
                </template>
            </el-table-column>

            <el-table-column :label="$t('common.created_at')">
                <template slot-scope="scope">
                    {{ scope.row.date }}
                </template>
            </el-table-column>
        </el-table>

        <Chart :history_list="history_list" class="mt-8"></Chart>
    </div>
</template>

<script>
import { getHistoryPayment } from '@/services/atosa-tiktok-ads/payment'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import rangerDateMixin from '@/plugins/mixins/ranger-date'
import moment from 'moment'
import Chart from './chart'
import ExcelSvg from '@/assets/svgs/excel.svg'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'

export default {
    components: {
        Chart,
        ExcelSvg
    },

    mixins: [selectedTiktokBusinessMixin, rangerDateMixin],

    data() {
        return {
            key: '',
            name: '',
            mail: '',
            ranger_date: [
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 31),
                new Date()
            ],
            history_list: []
        }
    },

    watch: {
        ranger_date() {
            this.getHistoryPayment()
        }
    },

    mounted() {
        if (this.$route.query.key) {
            this.key = this.$route.query.key
        }

        this.getHistoryPayment()
    },

    methods: {
        async handleExportExcel() {
            const start_time = moment(this.ranger_date[0]).format('DD-MM-YYYY')
            const end_time = moment(this.ranger_date[1]).format('DD-MM-YYYY')

            const headers = ['Ngày', 'Số tiền']

            const body = this.history_list.map((item) => {
                return [
                    item.date,
                    this.p__renderMoneyByCurrency(item.amount, 'VND')
                ]
            })

            const name = `doi_soat_ngay_${this.mail}_${this.name}_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        },

        async fetchHistoryPayment(page) {
            try {
                const start_time = moment(this.ranger_date[0]).format('X')
                const end_time =
                    +moment(this.ranger_date[1]).format('X') + 86_400

                const response = await getHistoryPayment({
                    name: this.name,
                    mail: this.mail,
                    page,
                    page_size: 100,
                    start_time,
                    end_time,
                    key: this.key
                })

                return {
                    page_count: response.data.page_result.page_count,
                    has_more: response.data.page_result.page_count > page,
                    list: response.data.data
                }
            } catch (error) {
                console.error(error)
            }

            return {
                page_count: 1,
                has_more: false,
                list: []
            }
        },

        async getHistoryPayment() {
            this.m__loading = true

            let page = 1
            const result = []

            const response = await this.fetchHistoryPayment(page)
            result.push(...response.list)

            if (response.page_count > 1) {
                while (page < response.page_count) {
                    page++

                    const temp = await this.fetchHistoryPayment(page)
                    result.push(...temp.list)
                }
            }

            const date_list = this.getDatesInRange(
                this.ranger_date[0],
                this.ranger_date[1]
            )
            const temp_history_list = []
            date_list.forEach((date) => {
                let amount = 0

                result.forEach((item) => {
                    const dateFromTimestamp = moment
                        .unix(item.body.event.transfer.created_at)
                        .format('DD/MM/YYYY')
                    if (dateFromTimestamp === date) {
                        amount += +item.body.event.amount
                    }
                })
                temp_history_list.push({
                    date,
                    amount
                })
            })

            this.history_list = temp_history_list

            this.m__loading = false
        },

        getDatesInRange(start_time, end_time) {
            const startDate = moment(start_time)
            const endDate = moment(end_time)

            const datesArray = []

            while (startDate.isSameOrBefore(endDate, 'day')) {
                datesArray.push(startDate.format('DD/MM/YYYY'))
                startDate.add(1, 'day')
            }

            return datesArray.reverse()
        },

        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const { property } = column

                const values = data.map((item) => {
                    if (property) {
                        const pathArray = property.split('.')
                        if (pathArray.length > 1) {
                            let value = item
                            for (const key of pathArray) {
                                value = value[key]
                            }

                            return Number(value)
                        }
                    }

                    return Number(item[property])
                })

                if (!values.every((value) => isNaN(value))) {
                    const total = this.calculateValue(values, property, data)

                    sums[index] = total
                } else {
                    sums[index] = ''
                }
            })
            return sums
        },

        calculateValue(values, column_property, data) {
            let total = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                } else {
                    return prev
                }
            }, 0)

            if (['amount'].some((item) => column_property === item)) {
                total = data.reduce((prev, curr) => {
                    const value = Number(curr.amount)
                    if (!isNaN(value)) {
                        return prev + value
                    } else {
                        return prev
                    }
                }, 0)
                total = `${this.p__renderMoneyByCurrency(total, 'VND')}`
            }
            return total
        }
    }
}
</script>
